import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  businessName: "",
  address: "",
  address2: "",
  city: "",
  state: "",
  zip: "",
  paymentSkip: false,
};

const onboardingSlice = createSlice({
  name: "onboarding",
  initialState,
  reducers: {
    setBusinessName: (state, action) => {
      state.businessName = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setAddress2: (state, action) => {
      state.address2 = action.payload;
    },
    setCity: (state, action) => {
      state.city = action.payload;
    },
    setState: (state, action) => {
      state.state = action.payload;
    },
    setZip: (state, action) => {
      state.zip = action.payload;
    },
    setPaymentSkip: (state, action) => {
      state.paymentSkip = action.payload;
    },
  },
});

export const {
  setBusinessName,
  setAddress,
  setAddress2,
  setCity,
  setState,
  setZip,
  setPaymentSkip,
} = onboardingSlice.actions;

export default onboardingSlice.reducer;
