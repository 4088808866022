import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import OnboardingWizard from "components/Onboarding/OnboardingWizard";
import Step1 from "../../components/Onboarding/Steps/Step1";

import { useAuth, useOrganizationList, useUser } from "@clerk/clerk-react";
import { getOnboardingStatus } from "../../api/client";
import { OnboardingStatus } from "../../enums/onboardingStatus";
import { setSessionToken } from "../../api/axios.config";

// Stripe
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

export default function WizardView() {
  const { isLoaded: isUserLoaded, user } = useUser();
  const { isLoaded: isOrgLoaded, setActive } = useOrganizationList();
  const { isLoaded: isAuthLoaded, isSignedIn, getToken } = useAuth();

  const [onboardingStatus, setOnboardingStatus] = useState(
    OnboardingStatus.UNKNOWN
  );
  const [checkCount, setCheckCount] = useState(0);

  useEffect(async () => {
    if (isUserLoaded && isAuthLoaded && isSignedIn) {
      const token = await getToken({ template: "inQ" });
      setSessionToken(token);

      const email = user.primaryEmailAddress?.emailAddress;

      const checkOnboardingStatus = setInterval(async () => {
        try {
          const status = await getOnboardingStatus(email);

          if (status !== OnboardingStatus.UNKNOWN) {
            setOnboardingStatus(status);
            clearInterval(checkOnboardingStatus); // Immediately stop further checks.
          } else {
            // 4 checks, total 3 seconds
            if (checkCount >= 3) {
              clearInterval(checkOnboardingStatus);
            } else {
              setCheckCount((prevCount) => prevCount + 1);
            }
          }
        } catch (error) {
          clearInterval(checkOnboardingStatus);
          console.error("Error checking user onboarding status:", error);
        }
      }, 750);

      return () => clearInterval(checkOnboardingStatus); // Clear interval on component unmount
    }
  }, [isUserLoaded, isAuthLoaded, isSignedIn, checkCount]);

  if (isAuthLoaded && !isSignedIn) {
    return <Redirect to="/auth/login" />;
  }

  if (
    !isUserLoaded ||
    !isOrgLoaded ||
    !isAuthLoaded ||
    onboardingStatus === OnboardingStatus.UNKNOWN
  ) {
    return <div>Please wait while we access your account...</div>;
  }

  if (onboardingStatus === OnboardingStatus.COMPLETE) {
    return <Redirect to="/client/dashboard" />;
  }

  setActive({ organization: process.env.REACT_APP_CLERK_ORG_ID });

  return (
    <Elements stripe={stripePromise}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8}>
          <OnboardingWizard
            validate
            steps={[
              {
                stepName: "Account",
                stepComponent: Step1,
                stepId: "account",
              },
            ]}
            title="Business Information"
            subtitle="This will let us know more about your business"
            finishButtonClick={(e) => console.log(e)}
          />
        </GridItem>
      </GridContainer>
    </Elements>
  );
}
