import api from "./axios.config";

export const getOnboardingStatus = async (email) => {
  try {
    const response = await api.get("/clients/onboarding-status", {
      params: { email },
    });
    return response.data.status;
  } catch (error) {
    console.error("Error getting onboarding status for client:", error);
    return false;
  }
};
