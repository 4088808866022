import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useAuth, useOrganizationList, useUser } from "@clerk/clerk-react";
import { getOnboardingStatus } from "../../api/client";
import { OnboardingStatus } from "../../enums/onboardingStatus";
import { setSessionToken } from "../../api/axios.config";

export default function IntermediaryAuth() {
  const { isLoaded: isUserLoaded, user } = useUser();
  const { isLoaded: isOrgLoaded, setActive } = useOrganizationList();
  const { isLoaded: isAuthLoaded, isSignedIn, orgRole, getToken } = useAuth();

  const [onboardingStatus, setOnboardingStatus] = useState(
    OnboardingStatus.UNKNOWN
  );
  const [checkCount, setCheckCount] = useState(0);

  useEffect(async () => {
    if (isUserLoaded && isAuthLoaded && isSignedIn) {
      const token = await getToken({ template: "inQ" });
      setSessionToken(token);

      const email = user.primaryEmailAddress?.emailAddress;

      const checkOnboardingStatus = setInterval(async () => {
        try {
          const status = await getOnboardingStatus(email);

          if (status !== OnboardingStatus.UNKNOWN) {
            setOnboardingStatus(status);
            clearInterval(checkOnboardingStatus); // Immediately stop further checks.
          } else {
            // 4 checks, total 3 seconds
            if (checkCount >= 3) {
              clearInterval(checkOnboardingStatus);
            } else {
              setCheckCount((prevCount) => prevCount + 1);
            }
          }
        } catch (error) {
          clearInterval(checkOnboardingStatus);
          console.error("Error checking user onboarding status:", error);
        }
      }, 750);

      return () => clearInterval(checkOnboardingStatus); // Clear interval on component unmount
    }
  }, [isUserLoaded, isAuthLoaded, isSignedIn, checkCount]);

  if (isAuthLoaded && !isSignedIn) {
    return <Redirect to="/auth/login" />;
  }

  if (
    !isUserLoaded ||
    !isOrgLoaded ||
    !isAuthLoaded ||
    onboardingStatus === OnboardingStatus.UNKNOWN
  ) {
    return <div>Please wait while we access your account...</div>;
  }

  if (onboardingStatus === OnboardingStatus.STARTED) {
    return <Redirect to="/client/onboarding" />;
  }

  setActive({ organization: process.env.REACT_APP_CLERK_ORG_ID });

  if (orgRole === "admin") {
    return <Redirect to="/admin/dashboard" />;
  } else {
    return <Redirect to="/client/dashboard" />;
  }
}
