import api from "./axios.config";

export const saveOnboarding = async (onboardingForm) => {
  try {
    const response = await api.post(
      "/businesses/save-onboarding",
      onboardingForm
    );
    return response.data.status;
  } catch (error) {
    console.error("Error saving onboarding information:", error);
    return error.status;
  }
};
