/*!

=========================================================
* Material Dashboard PRO React - v1.10.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Provider } from "react-redux";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";
import ClientLayout from "layouts/Client.js";
import OriginalLayout from "layouts/Original.js";

import { ClerkProvider } from "@clerk/clerk-react";

import "assets/scss/material-dashboard-pro-react.scss?v=1.10.0";
import IntermediaryAuth from "views/Dashboard/IntermediaryAuth";

import store from "./store";

const publishableKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY;

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <ClerkProvider publishableKey={publishableKey}>
        <Switch>
          <Route path="/rtl" component={RtlLayout} />
          <Route path="/auth" component={AuthLayout} />
          <Route path="/admin" component={AdminLayout} />
          <Route path="/client" component={ClientLayout} />
          <Route path="/original" component={OriginalLayout} />
          <Route path="/intermediary" component={IntermediaryAuth} />
          <Redirect from="/" to="/intermediary" />
        </Switch>
      </ClerkProvider>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);
