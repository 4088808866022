import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputAdornment from "@material-ui/core/InputAdornment";

// Material UI Icons
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";

// core components
import CustomInput from "components/CustomInput/CustomInput.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// State management
import { useDispatch, useSelector } from "react-redux";
import {
  setBusinessName,
  setAddress,
  setAddress2,
  setCity,
  setState,
  setZip,
} from "store/onboarding/onboardingSlice";

// Stripe
import { CardElement } from "@stripe/react-stripe-js";

import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";

import { USStates } from "variables/usStates";
import { setPaymentSkip } from "store/onboarding/onboardingSlice";

const styles = {
  infoText: {
    fontWeight: "300",
    margin: "10px 0 30px",
    textAlign: "center",
  },
  ...customSelectStyle,
};

const useStyles = makeStyles(styles);

const Step1 = () => {
  const dispatch = useDispatch();
  const paymentSkip = useSelector((state) => state.onboarding.paymentSkip);
  const selectedStateValue = useSelector(
    (storeState) => storeState.onboarding.state
  );
  const onboardingState = useSelector((state) => state.onboarding);

  const classes = useStyles();

  // Validation State
  const [businessNameValidation, setBusinessNameValidation] = React.useState(
    null
  );
  const [addressValidation, setAddressValidation] = React.useState(null);
  const [address2Validation, setAddress2Validation] = React.useState(null);
  const [cityValidation, setCityValidation] = React.useState(null);
  const [zipValidation, setZipValidation] = React.useState(null);

  const [touchedFields, setTouchedFields] = React.useState({});

  function getEndAdornment(fieldName) {
    const value = onboardingState[fieldName];

    let adornmentIcon = null;

    if (!touchedFields[fieldName]) {
      return adornmentIcon;
    }

    if (fieldName === "address2" || value) {
      adornmentIcon = <Check className={classes.success} />;
    } else {
      adornmentIcon = <Close className={classes.danger} />;
    }

    return <InputAdornment position="end">{adornmentIcon}</InputAdornment>;
  }

  // Verifies if a string has a given length or not
  const verifyLength = (value, length) => {
    if (value.length >= length) {
      return true;
    }
    return false;
  };

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={12} md={12} lg={10}>
        <CustomInput
          labelText="Business Name*"
          name="businessName"
          formControlProps={{
            fullWidth: true,
          }}
          success={businessNameValidation === "success"}
          error={businessNameValidation === "error"}
          inputProps={{
            onChange: (event) => {
              const value = event.target.value;
              if (verifyLength(value, 0)) {
                setBusinessNameValidation("success");
                dispatch(setBusinessName(value));
              } else {
                setBusinessNameValidation("error");
              }
              setTouchedFields((prev) => ({ ...prev, businessName: true }));
            },
            type: "text",
            maxLength: 100,
            endAdornment: getEndAdornment("businessName"),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={10}>
        <CustomInput
          labelText="Address*"
          id="address"
          name="address"
          formControlProps={{
            fullWidth: true,
          }}
          success={addressValidation === "success"}
          error={addressValidation === "error"}
          inputProps={{
            onChange: (event) => {
              const value = event.target.value;
              if (verifyLength(value, 0)) {
                setAddressValidation("success");
                dispatch(setAddress(value));
              } else {
                setAddressValidation("error");
              }
              setTouchedFields((prev) => ({ ...prev, address: true }));
            },
            type: "text",
            maxLength: 100,
            endAdornment: getEndAdornment("address"),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12} md={12} lg={10}>
        <CustomInput
          labelText="Address 2"
          id="address2"
          name="address2"
          formControlProps={{
            fullWidth: true,
          }}
          success={address2Validation === "success"}
          error={address2Validation === "error"}
          inputProps={{
            onChange: (event) => {
              const value = event.target.value;

              if (value) {
                setAddress2Validation("success");
                setTouchedFields((prev) => ({ ...prev, address2: true }));
              } else {
                setAddress2Validation(null);
                setTouchedFields((prev) => ({ ...prev, address2: false }));
              }

              dispatch(setAddress2(value));
            },
            type: "text",
            maxLength: 100,
            endAdornment: getEndAdornment("address2"),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={5}>
        <CustomInput
          labelText="City*"
          id="city"
          name="city"
          formControlProps={{
            fullWidth: true,
          }}
          success={cityValidation === "success"}
          error={cityValidation === "error"}
          inputProps={{
            onChange: (event) => {
              const value = event.target.value;
              if (verifyLength(value, 0)) {
                setCityValidation("success");
                dispatch(setCity(value));
              } else {
                setCityValidation("error");
              }
              setTouchedFields((prev) => ({ ...prev, city: true }));
            },
            type: "text",
            maxLength: 100,
            endAdornment: getEndAdornment("city"),
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={2}>
        <FormControl fullWidth className={classes.selectFormControl}>
          <InputLabel htmlFor="state" className={classes.onboardingSelectLabel}>
            State*
          </InputLabel>
          <Select
            value={selectedStateValue}
            MenuProps={{
              className: classes.selectMenu,
            }}
            classes={{
              select: classes.select,
            }}
            onChange={(event) => {
              const value = event.target.value;
              dispatch(setState(value));
              setTouchedFields((prev) => ({ ...prev, state: true }));
            }}
            inputProps={{
              name: "state",
              id: "state",
            }}
          >
            <MenuItem
              disabled
              classes={{
                root: classes.selectMenuItem,
              }}
            >
              Choose State
            </MenuItem>
            {USStates.map((state) => (
              <MenuItem
                key={state}
                classes={{
                  root: classes.selectMenuItem,
                  selected: classes.selectMenuItemSelected,
                }}
                value={state}
              >
                {state}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </GridItem>
      <GridItem xs={12} sm={3}>
        <CustomInput
          labelText="ZIP Code*"
          id="zip"
          name="zip"
          formControlProps={{
            fullWidth: true,
          }}
          success={zipValidation === "success"}
          error={zipValidation === "error"}
          inputProps={{
            onChange: (event) => {
              const value = event.target.value;
              if (verifyLength(value, 0)) {
                setZipValidation("success");
                dispatch(setZip(value));
              } else {
                setZipValidation("error");
              }
              setTouchedFields((prev) => ({ ...prev, zip: true }));
            },
            type: "text",
            endAdornment: getEndAdornment("zip"),
            maxLength: 10,
          }}
        />
      </GridItem>
      <GridItem xs={12} sm={12}>
        <h4 className={classes.infoText}>Payment Information</h4>
      </GridItem>
      <GridItem xs={12} sm={12} md={10}>
        <div className={classes.block}>
          <FormControlLabel
            control={
              <Switch
                checked={paymentSkip}
                onChange={(event) => {
                  const isChecked = event.target.checked;
                  dispatch(setPaymentSkip(isChecked));
                }}
                value="skip"
                classes={{
                  switchBase: classes.switchBase,
                  checked: classes.switchChecked,
                  thumb: classes.switchIcon,
                  track: classes.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label="Skip for now"
          />
        </div>
      </GridItem>
      <GridItem xs={12} sm={7}>
        {!paymentSkip && <CardElement />}
      </GridItem>
    </GridContainer>
  );
};

Step1.displayName = "Step1";

export default Step1;
