import PropTypes from "prop-types";

export const onboardingForm = {
  businessName: PropTypes.string,
  address: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  zip: PropTypes.string,
  paymentSkip: PropTypes.bool,
};
